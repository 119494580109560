import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const appUpdateApi = {
    saveAppUpgrade: (data) => AppHttpKit.postJSON(`/v1/appUpdate/saveAppUpgrade`,data),//添加APP更新信息
    listAppUpgrade:(data)=>AppHttpKit.postJSON("/v1/appUpdate/listAppUpgrade",data),//更新列表
    queryAppUpgradeById:(data)=>AppHttpKit.get(`/v1/appUpdate/queryAppUpgradeById/${data}`),//根据id查询更新信息
    deleteAppUpgradeById:(data)=>AppHttpKit.get(`/v1/appUpdate/deleteAppUpgradeById/${data}`),//删除app更新信息

}
export default appUpdateApi;