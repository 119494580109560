<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="tabChange">
      <el-tab-pane label="Android" name="android">
        <table-compnent
          :records="androidData"
          :showSelection="false"
          :showReset="false"
          :showSearch="false"
          @addHandler="addAndroidVersion"
          :loading="androidLoading"
          :showDelete="false"
          :showAdd="true"
          @searchHandler="androidQuery"
        >
          <template v-slot:content>
            <el-table-column
              prop="type"
              label="APP类型"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="versionName"
              label="版本名称"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="versionCode"
              label="版本号"
              width="100"
            ></el-table-column>
            <el-table-column prop="describe" label="描述信息"></el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="queryById(scope.row.id)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="deleteById(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </template>
        </table-compnent>
      </el-tab-pane>
      <el-tab-pane label="IOS" name="ios">
        <table-compnent
          :records="iosData"
          :showSelection="false"
          :showReset="false"
          :showSearch="false"
          @addHandler="addiosVersion"
          :loading="iosLoading"
          :showDelete="false"
          :showAdd="true"
          @searchHandler="iosQuery"
        >
          <template v-slot:content>
            <el-table-column
              prop="type"
              label="APP类型"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="versionName"
              label="版本名称"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="versionCode"
              label="版本号"
              width="100"
            ></el-table-column>
            <el-table-column prop="describe" label="描述信息"></el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="queryById(scope.row.id)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="deleteById(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </template>
        </table-compnent>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="currentVersion.id ? '编辑版本' : '新增版本'"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="currentVersion" ref="form" :rules="rules">
        <el-form-item label="版本名称" prop="versionName">
          <el-input
            v-model.trim="currentVersion.versionName"
            autocomplete="off"
            maxlength="40"
          ></el-input>
        </el-form-item>
        <el-form-item label="版本CODE" prop="versionCode">
          <el-input
            v-model.trim="currentVersion.versionCode"
            autocomplete="off"
            maxlength="40"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="下载地址"
          v-if="currentVersion.type == 'android'"
          prop="downloadUrl"
        >
          <el-input
            v-model.trim="currentVersion.downloadUrl"
            autocomplete="off"
            maxlength="255"
          ></el-input>
          <el-upload
            class="uploader"
            :show-file-list="false"
            :action="uploadVersionPath"
            :on-success="uploadSuccess"
            :on-error="uploadError"
            :before-upload="beforeBankImageUpload"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              支持格式：.apk ，单个文件不能超过500MB
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="MD5值" v-if="currentVersion.type == 'android'">
          <el-input
            v-model.trim="currentVersion.md5Data"
            autocomplete="off"
            maxlength="255"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="APK大小(M)"
          prop="apkSize"
          v-if="currentVersion.type == 'android'"
        >
          <el-input
            v-model.trim="currentVersion.apkSize"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否更新" prop="isUpdate">
          <el-select v-model="currentVersion.isUpdate" placeholder="请选择">
            <el-option v-for="(item,index) in ['否','是']" :key="item" :value="index" :label="item"></el-option>
            
          </el-select>
        </el-form-item>
        <el-form-item label="强制更新">
          <el-select v-model="currentVersion.forceUpdate" placeholder="请选择">
           <el-option v-for="(item,index) in ['否','是']" :key="item" :value="index" :label="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="更新方式">
          <el-select v-model="currentVersion.updateMethod" placeholder="请选择">
           <el-option v-for="(item,index) in ['后台更新','前台更新']" :key="item" :value="index+1" :label="item"></el-option>
          
          </el-select>
        </el-form-item>
        <el-form-item label="更新内容">
          <el-input
            type="textarea"
            :rows="4"
            v-model.trim="currentVersion.describe"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import TableCompnent from "@/components/table/TablesComponents";
import { uploadVersion } from "@/utils/UploaderUtils";
import appUpdateApi from "@/api/appUpdateApi";
export default {
  data() {
    return {
      activeName: "android",
      androidLoading: false,
      androidData: {},
      iosLoading: false,
      iosData: {},
      dialogFormVisible: false,
      uploadVersionPath: uploadVersion(),
      currentVersion: {
        apkSize: "",
        category: "",
        describe: "",
        downloadUrl: "",
        fileName: "",
        forceUpdate: 1,
        isUpdate: 1,
        md5Data: "",
        status: 1,
        type: "",
        updateMethod: 2,
        versionCode: "",
        versionName: "",
        versionTime: 0,
      },
      androidPage: { pageNum: 1, pageSize: 20, type: "android" },
      iosPage: { pageNum: 1, pageSize: 20, type: "ios" },
      rules: {
        versionName: [
          { required: true, trigger: "blur", message: "版本名称不能为空" },
        ],
        versionCode: [
          { required: true, trigger: "blur", message: "版本号不能为空" },
        ],
        downloadUrl: [
          { required: true, trigger: "blur", message: "下载地址不能为空" },
        ],
        apkSize: [
          { required: true, trigger: "blur", message: "APK大小不能为空" },
        ],
        status: [
          { required: true, trigger: "blur", message: "请选择是否更新" },
        ],
      },
    };
  },
  components: {
    TableCompnent,
  },
  filters: {
    selectFilter: function (val) {
      return val.toString();
    },
  },
  methods: {
    addAndroidVersion() {
      this.currentVersion = this.$options.data().currentVersion;
      this.currentVersion.type = "android";
      if (this.$refs.form) this.$refs.form.resetFields();
      this.dialogFormVisible = true;
    },
    addiosVersion() {
      this.currentVersion = this.$options.data().currentVersion;
      this.currentVersion.type = "ios";
      if (this.$refs.form) this.$refs.form.resetFields();
      this.dialogFormVisible = true;
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        console.log( this.currentVersion);
        this.currentVersion.status=1;
        appUpdateApi
          .saveAppUpgrade(this.currentVersion)
          .then((res) => {
            if (res.code === '200') {
              this.$message.success("版本保存成功");
              this.dialogFormVisible = false;
              this.tabChange();
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },
    uploadSuccess(resp) {
      if (resp.code === '200') {
        this.currentVersion.downloadUrl = resp.data.url;
        this.currentVersion.path = resp.data.path;
        this.currentVersion.fileName = resp.data.name;
      } else {
        this.$message.error(resp.msg);
      }
    },
    uploadError() {
      this.$message.error("安装包上传失败");
    },
    beforeBankImageUpload(file) {
      const isLt500M = file.size / 1024 / 1024 < 500;
      console.log(file.type);
      const isAndroid = file.type == "application/vnd.android.package-archive";
      if (!isAndroid) {
        this.$message.error("上传文件只能是apk格式!");
      }
      if (!isLt500M) {
        this.$message.error("上传的安装包大小不能超过 500MB!");
      }
      return isAndroid && isLt500M;
    },
    androidQuery(query) {
      this.androidPage.pageNum = query.pageNum;
      this.androidPage.pageSize = query.pageSize;
      this.query(this.androidPage);
    },
    iosQuery(query) {
      this.iosPage.pageNum = query.pageNum;
      this.iosPage.pageSize = query.pageSize;
      this.query(this.iosPage);
    },
    query(page) {
      if (page.type == "android") this.androidLoading = true;
      else this.iosLoading = true;
      appUpdateApi.listAppUpgrade(page).then((res) => {
        if (res.code === '200') {
          if (page.type == "android") {
            this.androidData = res.data;
            this.androidLoading = false;
          } else {
            this.iosData = res.data;
            this.iosLoading = false;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    tabChange() {
      if (this.activeName == "android") this.query(this.androidPage);
      else this.query(this.iosPage);
    },
    queryById(id) {
      appUpdateApi.queryAppUpgradeById(id).then((res) => {
        if (res.code === '200') {
          this.currentVersion = res.data;
          // this.currentVersion.status = res.data.status.toString();
          // this.currentVersion.forceUpdate = res.data.forceUpdate.toString();
          // this.currentVersion.updateMethod = res.data.updateMethod.toString();
          // if (this.currentVersion.updateMethod == "0")
          //   this.currentVersion.updateMethod = "";
          if (this.$refs.form) this.$refs.form.resetFields();
          this.dialogFormVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    deleteById(id) {
      this.$confirmDialog("确定要进行当前操作?", () => {
        appUpdateApi.deleteAppUpgradeById(id).then((res) => {
          if (res.code === '200') {
            this.$message.success("删除成功");
            this.tabChange();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__body {
    border: solid #f2f2f2;
    border-width: 1px 0 1px 0;
  }
  .el-form {
    width: 100%;
    margin: 0 auto;
  }
  .el-input,
  .el-textarea,
  .el-select {
    width: calc(100% - 110px);
  }
  .el-select .el-input {
    width: 100%;
  }
  .el-form-item__label {
    min-width: 110px;
  }
  .el-form-item__error {
    left: 110px;
  }
  .uploader {
    margin: 20px auto 0 110px;
    .el-upload__tip {
      display: inline-block;
      margin-left: 24px;
      color: #666;
    }
  }
}
</style>
